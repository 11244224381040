import React from 'react';
import { resetIdCounter, Tab, Tabs, TabList, TabPanel } from 'react-tabs';
resetIdCounter();

const ProductsDetailsTabs = () => {
    return (
      <div className="products-details-tabs">
        <Tabs>
          <TabList>
            <Tab>Description</Tab>
            <Tab>RSVP Link</Tab>
          </TabList>

          <TabPanel>
            <div className="products-description">
              <p>
                Here goes the description of the current event that is livestreaming!
              </p>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    )
}

export default ProductsDetailsTabs;  