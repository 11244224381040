import React, {useState} from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import ProductSlider from '../components/Shop/ProductSlider';
import ProductsDetailsTabs from '../components/Shop/ProductsDetailsTabs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion';

const ProductDetails = () => {
  const [eventData, setEventData] = useState({
    currentLivestream: "https://www.youtube.com/embed/k8LpUZe3kho",
    nextThreeEvents: {
      first: "https://www.youtube.com/embed/k8LpUZe3kho",
      second: "https://www.youtube.com/embed/k8LpUZe3kho",
      third: "https://www.youtube.com/embed/k8LpUZe3kho"
    }
  })

  return(
  <Layout>
    <SEO title="Livestream" />

    <Navbar />

    <PageBanner pageTitle="Livestream" />

    <div className="shop-details-area ptb-80">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            {/* <ProductSlider /> */}
            <iframe
              width="1200"
              height="600"
              src={eventData.currentLivestream}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div className="col-lg-12 col-md-12">
            <ProductsDetailsTabs />
          </div>
        </div>
      </div>
    </div>

    <div className="shop-details-area ptb-80">
        <div className="row align-items-center">
          <div className="faq-accordion">
            <Accordion allowZeroExpanded preExpanded={['a']}>
                <AccordionItem uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <span>
                                Upcoming event #1 
                            </span>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    
                        <div className="col-lg-5">
                          {/* <ProductSlider /> */}
                          <iframe
                            width="1200"
                            height="600"
                            src={eventData.currentLivestream}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <ProductsDetailsTabs />
                        </div>
                      
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
          </div>
        </div>
    </div>

    <Footer />
  </Layout>
)}

export default ProductDetails;